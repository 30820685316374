body {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*.ql-toolbar {*/
/*  position: fixed;*/
/*  background-color: white;*/
/*}*/
.ql-editor {
  min-height: 50vh;
  max-height: 50vh;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.home-wrapper {
  margin-top: 0px !important;
  width: 100% !important;
  display: block !important;
  height: 400px !important;
}

.home-editor {
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  height: 80% !important;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-right: 60px solid blue;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 60px solid transparent;
  border-bottom: 60px solid transparent;
  border-left: 60px solid green;
}
